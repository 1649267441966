<template>
  <v-menu
    ref="datePickerMenu"
    v-model="showDatePickerMenu"
    :close-on-content-click="false"
    :return-value.sync="localValue"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="localValue ? formattedValues.join(' - ') : ''"
        :label="label"
        prepend-inner-icon="date_range"
        readonly
        v-bind="attrs"
        v-on="on"
        outlined
        :hide-details="hideDetails"
        :dense="dense"
        :error-messages="errorMessages"
        @blur="(e) => $emit('blue', e)"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="localValue"
      no-title
      scrollable
      range
      color="primary"
    >
      <v-spacer></v-spacer>
      <v-btn
        text
        color="primary"
        @click="showDatePickerMenu = false"
      >
        Cancel
      </v-btn>
      <v-btn
        text
        color="primary"
        @click="updateValue"
      >
        OK
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
// Export the SFC
export default {
  // Name of the component
  name: "DateRangePicker",

  // Accept incoming data
  props: {
    value: {
      type: [String, Array],
      required: false,
      default: null
    },

    hideDetails: {
      type: Boolean,
      required: false,
      default: false
    },

    errorMessages: {
      type: Array,
      required: false,
      default: null
    },

    label: {
      type: String,
      required: false,
      default: "Date Range"
    },

    dense: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  // Define local data variables
  data: () => ({
    showDatePickerMenu: false,
    localValue: null
  }),

  // Define readonly data variables
  computed: {
    /**
     * Define function to write better dates for selected values
     *
     * @returns {void}
     */
    formattedValues() {
      return (this.localValue || []).map((_) => dayjs(_).format("ll"))
    }
  },

  // Define watchers for local data
  watch: {
    // Keep an eye out for v-model's value
    value: {
      handler(newValue) {
        this.localValue = newValue
      },

      deep: true
    }
  },

  // Define method functions
  methods: {
    /**
     * Called from the "OK" CTA in the Date Range Selector Popup
     *
     * @returns {void}
     */
    updateValue() {
      // Check if the value is not null
      if (!this.localValue) return
      // check if there are two values in dateRange
      if (this.localValue.length !== 2) return

      // Sort the values correctly
      this.localValue.sort((a, b) => dayjs(a).unix() - dayjs(b).unix())

      // Otherwise update it
      this.$refs.datePickerMenu.save(this.localValue)

      // Now emit the value for v-model
      this.$emit("input", this.localValue)
    },
  },

  /**
   * As soon as the data variables are ready
   *
   * @returns {void}
   */
  created() {
    // Set the prop value as the default value
    this.localValue = this.value
  }
}
</script>
